@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&family=Playfair+Display:wght@400;600&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root,
.app {
  height: 100%;
  background-color: #F4F6F8;
  @apply text-white;
}


.glowh1{
  font-size: 0.85em;
  color:#2CBCE9;
  margin: 0;
  margin-left: -62px;
  margin-top: -10px;
  padding: 0;
  font-weight: 1000;
}
